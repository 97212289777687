.payment-solutions__security-link {
  line-height: 100%;
}

.payment-solutions__card {
  margin-bottom: 24px;
  height: auto;
  align-self: center;
  border-radius: 6px;
  display: flex;
  width: 100%;
  padding: 0;
  flex-direction: row;
  cursor: pointer;

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  svg {
    vertical-align: middle;
    width: 80px;
    height: 80px;
    min-width: 56px;
  }

  &--chevron {
    svg {
      width: 20px !important;
      height: 18px !important;
      margin: 0 !important;
      min-width: 0 !important;

      path {
        fill: $andes-accent-color;
      }
    }

    &:hover {
      svg path {
        fill: $andes-blue-600;
      }
    }
  }

  h5 {
    width: 100%;
    margin: 0 0 4px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: rgb(0 0 0 / 90%);
  }

  p {
    height: auto;
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: rgb(0 0 0 / 55%);
  }

  a {
    font-family: "Proxima Nova";
    color: $andes-accent-color;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    .greater-than-symbol {
      vertical-align: middle;
    }
  }

  &-chevron {
    width: 20px !important;
    height: 18px !important;
    margin: 0 !important;
    min-width: 20px !important;
  }
}

.payment-solutions__card-format {
  flex-direction: column;
  align-items: center;
  width: 311px;
  padding: 24px 28px;

  h5 {
    text-align: center;
  }

  a {
    margin-top: 16px;
    display: block;
    text-align: center;
    font-weight: 600;
  }
}

.payment-solutions__card.whithout-card-item {
  cursor: default;

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: rgb(0 0 0 / 90%);
    text-align: left;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgb(0 0 0 / 55%);
    text-align: left;
  }
}
