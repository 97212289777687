.programm-lang-support {
  max-width: 100% !important;
  width: auto !important;
  height: 360px;

  &__title {
    width: 92vw;
    margin: 80px 10px 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: rgb(0 0 0 / 90%);
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: $black-7;
  }

  &__container {
    width: 92vw;
    display: flex;
    justify-content: center;
    margin: 40px 10px 10px 0;
  }
}
