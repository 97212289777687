.home-rebranding-carousel {
  padding-top: 30px;
  max-width: 1216px;
  margin: 0 auto;
  min-height: 339px;

  .carousel__slide {

    .andes-button {
      background-color: $andes-accent-color;
      color: $white;
      margin: 24px auto 0;
      display: block;
      line-height: 16px;
      padding: 17px 24px;
    }

    .wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 550px;
      border-radius: 16px;
      overflow: hidden;
    }

    @media (max-width: 950px) and (orientation: landscape) {
      .wrapper {
        height: 100%;
        width: 100%;
      }
    }

    .text-container {
      padding-top: 32px;
    }

    .title,
    .subtitle,
    .header {
      font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
      text-align: center;
    }

    .title {
      font-size: 28px;
      font-weight: 600;
      margin: 0 36px;
      line-height: 100%;
    }

    .subtitle {
      font-size: 16px;
      line-height: 18px;
      margin: 8px 20px 0;
      font-weight: 400;
    }

    .title-mp,
    .subtitle-mp {
      color: $white;
    }

    .title-ml,
    .subtitle-ml {
      color: $black-8;
    }

    .slide-button {
      max-width: 90%;
    }

    .image-container {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      width: 90%;
      max-width: fit-content;

      img {
        width: 100%;
        margin: 0 auto;
        object-fit: scale-down;
      }
    }

    @media (max-width: 950px) and (orientation: landscape) {
      .image-container {
        img {
          width: inherit;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .andes-carousel-snapped__container {
      .andes-carousel-snapped__control--size-large {
        display: none;
      }
    }
  }
}

.devsite-carousel-item {
  cursor: auto !important;
}
