.carousel-devsite-home-rebranding-container {
  height: auto;
  border-radius: 16px;
}

.home-rebranding-carousel {
  overflow: hidden;
  max-width: 1216px;
  margin: 0 auto;
  min-height: 339px;

  .carousel__slide {

    .andes-button {
      width: auto;
      display: inline-block;
      margin-right: 16px;
    }

    .wrapper {
      justify-content: center;
      flex-direction: row;
      width: 100%;
      height: auto;
      margin: 0;
    }

    .text-container {
      margin-right: 48px;
      width: 510px;
    }

    .image-container {
      margin-top: 0;
      max-width: 27vw;
      height: 336px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        object-fit: scale-down;
      }
    }

    .header,
    .title,
    .subtitle {
      text-align: left;
    }

    .header {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
    }

    .title {
      font-size: 28px;
      margin: 0;
    }

    .subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      margin-left: 0;
    }

    .title-mp,
    .subtitle-mp {
      color: $white;
    }

    .title-ml,
    .subtitle-ml {
      color: $black-8;
    }

    .image-container {
      svg {
        width: 100%;
        margin: 0;
      }
    }
  }
}
