.payment-solutions__card {
  margin-bottom: 80px;
  padding: 24px 28px;
  width: 311px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  min-height: 300px;
  border-radius: 6px;

  &--content {
    display: unset;

    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: rgb(0 0 0 / 90%);
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: rgb(0 0 0 / 55%);
    }
  }

  h5 {
    margin: 0 0 8px;
    font-size: 24px;
    line-height: 24px;
  }

  p {
    height: auto;
    word-wrap: break-word;
    width: fit-content;
    margin: 0;
    font-size: 16px;
    line-height: 18px;
  }

  a {
    font-weight: 600;
  }

  svg {
    vertical-align: middle;
    margin: 0 0 24px;
    width: 100px;
    height: 100px;
    min-width: 56px;
  }
}

.payment-solutions__card-format {
  min-width: 490px;

  &:first-of-type {
    margin-right: 16px;
  }
}

.payment-solutions__card.whithout-card-item {
  cursor: default;
  min-height: 0;
  padding-bottom: 0;
  margin-bottom: 0;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: rgb(0 0 0 / 90%);
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: rgb(0 0 0 / 55%);
  }
}
