.home-help {
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 106px;
  justify-content: center;
  max-width: 100% !important;
  width: auto !important;

  .home-help__main--title {
    font-weight: 700;
    margin: 40px 0;
    font-size: 32px;
    line-height: 32px;
    color: rgb(0 0 0 / 90%);
  }

  .home-help__cards--container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 90%;

    .home-help__card {
      text-align: center;
      margin: 40px 34px;

      .home-help--image {
        margin-bottom: 16px;
      }

      p {
        text-align: center;
        word-wrap: break-word;
        width: fit-content;
      }
    }
  }
}
