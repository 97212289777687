.home-hero {
  background: $white;

  &-container {
    margin-top: 65px;
    text-transform: unset;
    max-width: 900px;

    .andes-landings-container {
      max-width: fit-content;
    }

    h3 {
      font-size: 14px !important;
      font-weight: 600 !important;
    }

    h2 {
      text-transform: unset !important;
      font-weight: 700 !important;
    }
  }
}

.home-hero-white-text {
  h2, h3, p {
    color: $white !important;
  }
}
