.home-help {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 866px;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  background-color: $white;
  height: auto;

  .home-help__cards--container {
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
    display: flex;
  }

  .andes-landings-container {
    max-width: fit-content;
  }

  .cards-home-help {
    text-align: center;
    margin-top: 40px;
  }

  .card {
    display: inline-block;
    width: 31%;
    margin: 0 10px;
    border-radius: 8px;
    margin-bottom: 24px;
    cursor: pointer;
    padding: 32px 24px 0px 24px;
    transition: box-shadow 0.2s ease-in-out;
    height: 150px;
    box-shadow: none;

    &:hover {
      box-shadow: 0 8px 16px 0 $black-5;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h5 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        color: $black;
      }

      span {
        font-size: 16px;
        color: $dark-gray-11;
        font-weight: 400;
        line-height: 20px;
      }
    }

    @media (max-width: 768px), (max-width: 1024px) and (orientation: landscape) {
      width: 100%;
    }
  }

  @media (min-width: 1891px) {
    .card {
      width: 32%;
    }
  }

  @media (min-width: 1025px) and (max-width: 1273px) {
    .card {
      width: 40%;
    }
  }

  @media (min-width: 1025px) and (max-width: 1789px) {
    .card {
      padding: 35px 24px 0px 24px;
      height: 180px;
    }
  }

  @media (max-width: 1024px) {
    .card-help-details {
      max-width: 100%;
    }

    .card {
      padding: 24px 16px 24px 16px;
      height: auto;
    }

    .card-help-details-icon {
      margin-right: 16px !important;
    }
  }

  .card-help-details {
    display: flex;
    text-align: left;
  }

  .card-help-details-icon {
    margin-right: 24px;
    margin-top: 4px !important;

    svg {
      path {
        fill: $andes-accent-color;
      }
    }
  }
}
