.payment-solutions {
  flex-direction: column;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
  justify-content: center;
  max-width: 100% !important;
  width: auto !important;
  padding: 80px 0;

  .payment-solutions__main-title {
    font-size: 32px;
    margin: 0;
  }

  .payment-solutions__main-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: $black-7;
  }

  .payment-solutions__cards-container {
    flex-direction: row;
    margin-top: 48px;
    margin-bottom: 0 !important;
    text-align: left;

    .payment-solutions__security-link {
      font-size: 16px;
    }

    &--security {
      svg {
        height: 100px;
        width: 100px;
        margin-right: unset;
      }
    }
  }
}

.dx-devsite-home-images-brand-color {
  fill: $andes-brand-main;
}
