.payment-solutions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 866px;
  padding: 25px 25px 32px;
  background-color: $white;

  .payment-solutions__main-subtitle {
    text-align: center;
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $black-7;
  }

  .andes-landings-container {
    max-width: fit-content;
  }

  .payment-solutions__main-title {
    text-align: center;
    font-size: 24px;
    margin: 56px 0 8px;
    line-height: 100%;
    font-weight: 600;
    color: $black-8;
  }

  .payment-solutions__cards-container {
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 56px;
    width: 100%;
    height: auto;
    max-height: 800px;
    text-align: left;
    display: flex;

    &:last-of-type {
      margin-bottom: 68px;
    }

    &--security {
      svg {
        height: 51px;
        width: 42px;
        margin-right: 21px;
      }
    }
  }

  .payment-solutions__security-link {
    font-size: 14px;
  }
}

.dx-devsite-home-images-brand-color {
  fill: $andes-brand-main;
}
