@import "../../styles/variables";
@import "../../styles/common";
@import "./components/HomeHero/styles.mobile.scss";
@import "./components/HomePaymentSolutions/styles.mobile";
@import "./components/HomePaymentSolutions/PaymentSolutionsCard/styles.mobile";
@import "./components/ProgrammingLangSupported/styles.mobile";
@import "./components/HomeBanners/styles.mobile.scss";
@import "./components/HomeCarousel/styles.mobile.scss";
@import "./components/HomeHelp/styles.mobile";

@import '~@andes-landings/title/index';
@import '~@andes-landings/background/index';
@import '~@andes-landings/hero/index';


/** For Andes Hero */
@import '~@andes-landings/actions/index';
@import '~@andes-landings/bullets/index';
@import '~@andes-landings/button/index';
@import '~@andes-landings/image-container/index';
@import '~@andes-landings/layout/index';
@import '~@andes-landings/moneyamount/index';
@import '~@andes-landings/typography/index';

// Para desktop
@media (width >= 1024px) {
  @import "./components/HomeHero/styles.desktop.scss";
  @import "./components/HomePaymentSolutions/PaymentSolutionsCard/styles.desktop";
  @import "./components/HomePaymentSolutions/styles.desktop";
  @import "./components/ProgrammingLangSupported/styles.desktop";
  @import "./components/HomeCarousel/styles.desktop.scss";
  @import "./components/HomeHelp/styles.desktop";
}

.main-page-container {
  margin-left: 0 !important;
}

.home {
  margin: 0 auto;
  min-height: 90vh !important;
  background: $white;
}

.body-overflow-hidden {
  overflow: hidden !important;
}
